<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    points() {
      return () => import(`@/components/Themes/${this.$cookies.get('theme') ?? 'default'}/Loyalty/Point/List`);
    }
  },
  methods: {},
};
</script>
<template>
  <component :is="points" v-if="profile && points"></component>
</template>